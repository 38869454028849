
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutqhPNssP2k1Meta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/about.vue?macro=true";
import { default as cabinet5n7bQIIA83Meta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/account/cabinet.vue?macro=true";
import { default as _91id_93FwvQ8homIfMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/actions/[id].vue?macro=true";
import { default as listM1SVLVYx7QMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/actions/list.vue?macro=true";
import { default as _91id_93Etqk1c8pjGMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/articles/[id].vue?macro=true";
import { default as listyQY9gHscn1Meta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/articles/list.vue?macro=true";
import { default as finalStepLoP10MjF7qMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/cart/finalStep.vue?macro=true";
import { default as firstStepXyASQzanjtMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/cart/firstStep.vue?macro=true";
import { default as secondStepfeI8SwnvDvMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/cart/secondStep.vue?macro=true";
import { default as contactszx3nMrs4HHMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/contacts.vue?macro=true";
import { default as custompFPDmto5LqMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/custom.vue?macro=true";
import { default as delivery8lCyJUtrfrMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/delivery.vue?macro=true";
import { default as feedbackyrBJi6KcRdMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/feedback.vue?macro=true";
import { default as indexNIB2IkBhsRMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/index.vue?macro=true";
import { default as legalttRmr9SoDwMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/legal.vue?macro=true";
import { default as _91id_93kQQH53gCs9Meta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue?macro=true";
import { default as _91id_93SNqYdRSuXhMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue?macro=true";
import { default as partnershipeDvxOZoZOPMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/partnership.vue?macro=true";
import { default as _91id_93pqwc4NKVb9Meta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/restaurants/[id].vue?macro=true";
import { default as list4ODUNehGgcMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/restaurants/list.vue?macro=true";
import { default as offmMzS2Q0peaMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/system/off.vue?macro=true";
import { default as vacancyxpVmjWtatZMeta } from "D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/vacancy.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: {"MetaTags":[{"Name":"description","Content":"На данной странице можно ознакомиться с историей и описанием кафе-ресторана \"Мияке\""},{"Name":"og:description","Content":"На данной странице можно ознакомиться с историей и описанием кафе-ресторана \"Мияке\""}]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/about.vue")
  },
  {
    name: "account-cabinet",
    path: "/cabinet",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/account/cabinet.vue")
  },
  {
    name: "actions-id",
    path: "/action/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/actions/[id].vue")
  },
  {
    name: "actions-list",
    path: "/actions",
    meta: {"MetaTags":[{"Name":"description","Content":"На странице представлена вся информация о акциях, проходящих на данный момент в ресторане."},{"Name":"og:description","Content":"На странице представлена вся информация о акциях, проходящих на данный момент в ресторане."}]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/actions/list.vue")
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/articles/[id].vue")
  },
  {
    name: "articles-list",
    path: "/articles",
    meta: {"MetaTags":[{"Name":"description","Content":"На данной странице размещены интересные статьи и полезные материалы из кафе-ресторана Мияке в Малоярославце"},{"Name":"og:description","Content":"На данной странице размещены интересные статьи и полезные материалы из кафе-ресторана Мияке в Малоярославце"}]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/articles/list.vue")
  },
  {
    name: "cart-finalStep",
    path: "/order/complete/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/cart/finalStep.vue")
  },
  {
    name: "cart-firstStep",
    path: "/order",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/cart/firstStep.vue")
  },
  {
    name: "cart-secondStep",
    path: "/order/delivery",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/cart/secondStep.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: {"MetaTags":[{"Name":"description","Content":"На этой странице указаны все контакты Мияке в Малоярославце: адрес ресторана, контактные телефоны, e-mail, часы работы."},{"Name":"og:description","Content":"На этой странице указаны все контакты Мияке в Малоярославце: адрес ресторана, контактные телефоны, e-mail, часы работы."}]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/contacts.vue")
  },
  {
    name: "custom",
    path: "/custom",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/custom.vue")
  },
  {
    name: "delivery",
    path: "/delivery",
    meta: {"MetaTags":[{"Name":"description","Content":"Вся информация касаемо доставки и оплаты: зоны доставки, минимальная стоимость заказа, способы оплаты, часы работы."},{"Name":"og:description","Content":"Вся информация касаемо доставки и оплаты: зоны доставки, минимальная стоимость заказа, способы оплаты, часы работы."}]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/delivery.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    meta: {"MetaTags":[{"Name":"description","Content":"Отзывы о ресторане Мияке в Малоярославце. Читайте отзывы наших посетителей и делитесь своими впечатлениями о нашем заведении. Страница №1"},{"Name":"og:description","Content":"Отзывы о ресторане Мияке в Малоярославце. Читайте отзывы наших посетителей и делитесь своими впечатлениями о нашем заведении. Страница №1"}]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/feedback.vue")
  },
  {
    name: "index",
    path: "/",
    meta: {"MetaTags":[{"Name":"description","Content":"Официальный сайт «Мияке» в Малоярославце. У нас вы можете заказать еду с доставкой на дом и в офис ежедневно с 11.00 до 23.00. Ждём вас!"},{"Name":"og:description","Content":"Официальный сайт «Мияке» в Малоярославце. У нас вы можете заказать еду с доставкой на дом и в офис ежедневно с 11.00 до 23.00. Ждём вас!"}]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/legal.vue")
  },
  {
    name: "menu-group-id",
    path: "/menu/group/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    name: "menu-product-id",
    path: "/menu/product/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "partnership",
    path: "/partners",
    meta: {"MetaTags":[{"Name":"description","Content":"Отзывы о ресторане Мияке в Малоярославце. Читайте отзывы наших посетителей и делитесь своими впечатлениями о нашем заведении."},{"Name":"og:description","Content":"Отзывы о ресторане Мияке в Малоярославце. Читайте отзывы наших посетителей и делитесь своими впечатлениями о нашем заведении."}]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/partnership.vue")
  },
  {
    name: "restaurants-id",
    path: "/restaurants-details/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/restaurants/[id].vue")
  },
  {
    name: "restaurants-list",
    path: "/rest",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/restaurants/list.vue")
  },
  {
    name: "system-off",
    path: "/system/off",
    meta: {"HideFooter":true,"HideHeader":true},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/system/off.vue")
  },
  {
    name: "vacancy",
    path: "/vacancy",
    meta: {"MetaTags":[{"Name":"description","Content":"Ознакомьтесь с вакансиями, открытыми на данный момент в Мияке (Малоярославец). Вы можете отправить свое резюме через специальную форму."},{"Name":"og:description","Content":"Ознакомьтесь с вакансиями, открытыми на данный момент в Мияке (Малоярославец). Вы можете отправить свое резюме через специальную форму."}]},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/vacancy.vue")
  },
  {
    name: "group|139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b",
    path: "/menu/kombo",
    meta: {"GroupID":"139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b","ID":"139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b","IsGroupPage":true,"MetaTags":[{"Content":"КОМБО – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"КОМБО – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"КОМБО: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/kombo/all",
    name: "group|139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b|redirect-all",
    redirect: "/menu/kombo"
  },
  {
    name: "group|b6ae2f8b-5992-45cf-8877-b06f008f31f5",
    path: "/menu/pizza-v-neapolitanskom-stile-p",
    meta: {"AdditionalSeoText":"","GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","ID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","IsGroupPage":true,"MetaTags":[{"Content":"Пицца в неаполитанском стиле – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца в неаполитанском стиле – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Пицца в неаполитанском стиле: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/pizza-v-neapolitanskom-stile-p/all",
    name: "group|b6ae2f8b-5992-45cf-8877-b06f008f31f5|redirect-all",
    redirect: "/menu/pizza-v-neapolitanskom-stile-p"
  },
  {
    name: "group|ec5845bd-4e70-41fa-84a6-b08a00e8badd",
    path: "/menu/ec5845bd-4e70-41fa-84a6-b08a00e8badd",
    meta: {"AdditionalSeoText":"","GroupID":"ec5845bd-4e70-41fa-84a6-b08a00e8badd","ID":"ec5845bd-4e70-41fa-84a6-b08a00e8badd","IsGroupPage":true,"MetaTags":[{"Content":"Конструктор половинок – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Конструктор половинок – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Конструктор половинок: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/ec5845bd-4e70-41fa-84a6-b08a00e8badd/all",
    name: "group|ec5845bd-4e70-41fa-84a6-b08a00e8badd|redirect-all",
    redirect: "/menu/ec5845bd-4e70-41fa-84a6-b08a00e8badd"
  },
  {
    name: "group|f4aab3c9-8f46-4626-9197-ac6100889e11",
    path: "/menu/obedennoe-predlozhenie",
    meta: {"GroupID":"f4aab3c9-8f46-4626-9197-ac6100889e11","ID":"f4aab3c9-8f46-4626-9197-ac6100889e11","IsGroupPage":true,"MetaTags":[{"Content":"Обеденное предложение – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Обеденное предложение – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Обеденное предложение: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/obedennoe-predlozhenie/all",
    name: "group|f4aab3c9-8f46-4626-9197-ac6100889e11|redirect-all",
    redirect: "/menu/obedennoe-predlozhenie"
  },
  {
    name: "group|7945f4a1-9a8c-4544-9718-a82a009e163f",
    path: "/menu/sushi",
    meta: {"AdditionalSeoText":"","GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","ID":"7945f4a1-9a8c-4544-9718-a82a009e163f","IsGroupPage":true,"MetaTags":[{"Name":"description","Content":"Бесплатная доставка суши на дом и в офис от «Мияке» Малоярославец. Заказ онлайн и по телефону 8 (920) 09-444-22, оплата наличными и картой."},{"Name":"og:description","Content":"Бесплатная доставка суши на дом и в офис от «Мияке» Малоярославец. Заказ онлайн и по телефону 8 (920) 09-444-22, оплата наличными и картой."}],"SeoText":"","SubgroupID":"7945f4a1-9a8c-4544-9718-a82a009e163f","Title":"Суши (Классические суши): доставка в Малоярославце | «Мияке»","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    name: "group|6b2160c7-6a39-42c1-b8bf-a82a009e121b",
    path: "/menu/ostrye-sushi",
    meta: {"GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","ID":"6b2160c7-6a39-42c1-b8bf-a82a009e121b","IsGroupPage":true,"MetaTags":[{"Content":"Суши (Острые суши) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суши (Острые суши) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"6b2160c7-6a39-42c1-b8bf-a82a009e121b","Title":"Суши (Острые суши): доставка в Малоярославце | «Мияке»","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    name: "group|80fd2e84-50c6-4444-84d8-a82a009e0f4e",
    path: "/menu/rolly",
    meta: {"AdditionalSeoText":"","GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","ID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","IsGroupPage":true,"MetaTags":[{"Name":"description","Content":"Заказать роллы  с бесплатной доставкой в Малоярославце. Широкий выбор японских блюд, только свежие ингредиенты и частые акции."},{"Name":"og:description","Content":"Заказать роллы  с бесплатной доставкой в Малоярославце. Широкий выбор японских блюд, только свежие ингредиенты и частые акции."}],"SeoText":"","SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Роллы (Классические роллы): доставка в Малоярославце | «Мияке»","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    name: "group|16fc5e8e-2f38-497b-b3d3-a82a009e1570",
    path: "/menu/goryachie-rolly",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","ID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","IsGroupPage":true,"MetaTags":[{"Content":"Роллы (Горячие роллы) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Роллы (Горячие роллы) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Роллы (Горячие роллы): доставка в Малоярославце | «Мияке»","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    name: "group|80adad0c-dd93-4c6d-805a-a82a009e13e2",
    path: "/menu/assorti",
    meta: {"GroupID":"80adad0c-dd93-4c6d-805a-a82a009e13e2","ID":"80adad0c-dd93-4c6d-805a-a82a009e13e2","IsGroupPage":true,"MetaTags":[{"Content":"Ассорти – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ассорти – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Ассорти: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/assorti/all",
    name: "group|80adad0c-dd93-4c6d-805a-a82a009e13e2|redirect-all",
    redirect: "/menu/assorti"
  },
  {
    name: "group|b176ed6a-62af-4106-b930-a82a009e17ee",
    path: "/menu/salaty",
    meta: {"AdditionalSeoText":"","GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","ID":"b176ed6a-62af-4106-b930-a82a009e17ee","IsGroupPage":true,"MetaTags":[{"Content":"Салаты/Закуски – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Салаты/Закуски – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Салаты/Закуски: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/salaty/all",
    name: "group|b176ed6a-62af-4106-b930-a82a009e17ee|redirect-all",
    redirect: "/menu/salaty"
  },
  {
    name: "group|f63dc1f6-ee13-452a-85b2-a82a009e1262",
    path: "/menu/supy",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","ID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","IsGroupPage":true,"MetaTags":[{"Content":"Супы – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Супы – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Супы: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/supy/all",
    name: "group|f63dc1f6-ee13-452a-85b2-a82a009e1262|redirect-all",
    redirect: "/menu/supy"
  },
  {
    name: "group|1614f599-c2d2-4870-9c5f-a82a009e15f8",
    path: "/menu/garniry",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","ID":"1614f599-c2d2-4870-9c5f-a82a009e15f8","IsGroupPage":true,"MetaTags":[{"Content":"Горячие блюда (Гарниры) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Горячие блюда (Гарниры) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"1614f599-c2d2-4870-9c5f-a82a009e15f8","Title":"Горячие блюда (Гарниры): доставка в Малоярославце | «Мияке»","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    name: "group|e91c64eb-7e30-448a-8f70-a82a009e0f94",
    path: "/menu/lapsha-ris",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","ID":"e91c64eb-7e30-448a-8f70-a82a009e0f94","IsGroupPage":true,"MetaTags":[{"Content":"Горячие блюда (Лапша / Рис) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Горячие блюда (Лапша / Рис) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"e91c64eb-7e30-448a-8f70-a82a009e0f94","Title":"Горячие блюда (Лапша / Рис): доставка в Малоярославце | «Мияке»","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    name: "group|21ac3aae-b7d7-48aa-a182-a82a009e1685",
    path: "/menu/goryachie-blyuda",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","ID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","IsGroupPage":true,"MetaTags":[{"Content":"Горячие блюда (Основные блюда) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Горячие блюда (Основные блюда) – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Горячие блюда (Основные блюда): доставка в Малоярославце | «Мияке»","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    name: "group|26467268-0271-4f6f-8610-a82a009e16cb",
    path: "/menu/deserty",
    meta: {"GroupID":"26467268-0271-4f6f-8610-a82a009e16cb","ID":"26467268-0271-4f6f-8610-a82a009e16cb","IsGroupPage":true,"MetaTags":[{"Content":"Десерты – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Десерты – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Десерты: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/deserty/all",
    name: "group|26467268-0271-4f6f-8610-a82a009e16cb|redirect-all",
    redirect: "/menu/deserty"
  },
  {
    name: "group|f6ba830b-2569-4718-8f64-aba3007450c3",
    path: "/menu/napitki",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","ID":"f6ba830b-2569-4718-8f64-aba3007450c3","IsGroupPage":true,"MetaTags":[{"Content":"Напитки – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Напитки – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Напитки: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/napitki/all",
    name: "group|f6ba830b-2569-4718-8f64-aba3007450c3|redirect-all",
    redirect: "/menu/napitki"
  },
  {
    name: "group|63f46009-62f4-4fee-bab4-a82a009e0fe4",
    path: "/menu/detskoe-menyu",
    meta: {"GroupID":"63f46009-62f4-4fee-bab4-a82a009e0fe4","ID":"63f46009-62f4-4fee-bab4-a82a009e0fe4","IsGroupPage":true,"MetaTags":[{"Content":"Детское меню – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Детское меню – раздел меню на сайте ресторана «Мияке». Бесплатная доставка на дом в Малоярославце, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Детское меню: доставка в Малоярославце | «Мияке»"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/detskoe-menyu/all",
    name: "group|63f46009-62f4-4fee-bab4-a82a009e0fe4|redirect-all",
    redirect: "/menu/detskoe-menyu"
  },
  {
    path: "/menu",
    name: "group|redirect-default-link",
    redirect: "/menu/kombo"
  },
  {
    name: "product|26467268-0271-4f6f-8610-a82a009e16cb|0077cb44-2337-471d-b1aa-50573d361c86",
    path: "/product/0077cb44-2337-471d-b1aa-50573d361c86",
    meta: {"GroupID":"26467268-0271-4f6f-8610-a82a009e16cb","GroupLink":"/menu/deserty","GroupTitle":"Десерты","ID":"0077cb44-2337-471d-b1aa-50573d361c86","MetaTags":[{"Content":"Эклер в ассортименте 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Эклер в ассортименте 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Эклер в ассортименте: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|00cdcb4a-d27c-44f5-a78d-7e27d5f5c4f2",
    path: "/product/fokachcha-s-provanskimi-travami",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"00cdcb4a-d27c-44f5-a78d-7e27d5f5c4f2","MetaTags":[{"Content":"Фокачча с прованскими травами 150 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Фокачча с прованскими травами 150 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Фокачча с прованскими травами: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|030dd365-b116-4417-a890-74f359d938fb",
    path: "/product/kaliforniya-s-krevetkoy",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"030dd365-b116-4417-a890-74f359d938fb","MetaTags":[{"Content":"Калифорния с   креветкой 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Калифорния с   креветкой 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Калифорния с   креветкой: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f63dc1f6-ee13-452a-85b2-a82a009e1262|03cf5a3a-4a6e-4828-b0ff-f77db18d8ca9",
    path: "/product/sup-ramen-s-kuritsey",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","GroupLink":"/menu/supy","GroupTitle":"Супы","ID":"03cf5a3a-4a6e-4828-b0ff-f77db18d8ca9","MetaTags":[{"Content":"Суп Рамен с курицей 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суп Рамен с курицей 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Суп Рамен с курицей: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|0835f11b-ee2f-4bed-8566-7e3b79477186",
    path: "/product/tepliy-salat-s-kalmarami-avokado-i-tomatami",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"0835f11b-ee2f-4bed-8566-7e3b79477186","MetaTags":[{"Content":"Теплый салат с кальмарами, авокадо и томатами 570 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Теплый салат с кальмарами, авокадо и томатами 570 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Теплый салат с кальмарами, авокадо и томатами: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|09fd8065-e15d-456a-bc4b-bf55b6e99872",
    path: "/product/sok-svezh",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"09fd8065-e15d-456a-bc4b-bf55b6e99872","MetaTags":[{"Content":"Свежевыжатый сок 0,3 л 390 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Свежевыжатый сок 0,3 л 390 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Свежевыжатый сок 0,3 л: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|0d7f2923-b546-48d9-b503-e8da18fe0aa5",
    path: "/product/0d7f2923-b546-48d9-b503-e8da18fe0aa5",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"0d7f2923-b546-48d9-b503-e8da18fe0aa5","MetaTags":[{"Content":"Пицца  Карбонара 850 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца  Карбонара 850 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца  Карбонара: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|0e1f37a6-f72c-4e4f-93d2-c947207d5125",
    path: "/product/roll-kanada",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"0e1f37a6-f72c-4e4f-93d2-c947207d5125","MetaTags":[{"Content":"Канада 630 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Канада 630 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Канада: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|102b271a-5b2d-4fc3-a572-e9e7f80feeda",
    path: "/product/102b271a-5b2d-4fc3-a572-e9e7f80feeda",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"102b271a-5b2d-4fc3-a572-e9e7f80feeda","MetaTags":[{"Content":"Ролл с гребешком в трюфельном айоли 520 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с гребешком в трюфельном айоли 520 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с гребешком в трюфельном айоли: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|6b2160c7-6a39-42c1-b8bf-a82a009e121b|105f26a9-f24b-489d-8185-b78f21aec8cd",
    path: "/product/sushi-ostriy-losos",
    meta: {"GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","GroupLink":"/menu/ostrye-sushi","GroupTitle":"Острые суши","ID":"105f26a9-f24b-489d-8185-b78f21aec8cd","MetaTags":[{"Content":"Суши Острый лосось 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суши Острый лосось 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"6b2160c7-6a39-42c1-b8bf-a82a009e121b","Title":"Суши Острый лосось: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|119ec3d8-d9d8-4457-adc5-e84cc45c6d2f",
    path: "/product/mimaki",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"119ec3d8-d9d8-4457-adc5-e84cc45c6d2f","MetaTags":[{"Content":"Мимаки 375 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мимаки 375 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Мимаки: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80adad0c-dd93-4c6d-805a-a82a009e13e2|135bd6c4-9838-4610-bcc4-3c9174aa06e6",
    path: "/product/assorti-goryachikh-roll",
    meta: {"AdditionalSeoText":"","GroupID":"80adad0c-dd93-4c6d-805a-a82a009e13e2","GroupLink":"/menu/assorti","GroupTitle":"Ассорти","ID":"135bd6c4-9838-4610-bcc4-3c9174aa06e6","MetaTags":[{"Content":"Ассорти горячих ролл 1950 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ассорти горячих ролл 1950 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Ассорти горячих ролл: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|1418c2e5-c1f6-4550-822b-9366eec6c1f2",
    path: "/product/baklazhany-strachatella-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"1418c2e5-c1f6-4550-822b-9366eec6c1f2","MetaTags":[{"Content":"Баклажаны-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Баклажаны-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Баклажаны-страчателла: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|15fe4ad4-4719-4f1c-bfa1-ecaf19ecbd05",
    path: "/product/kurinoye-file-na-grile-v-tryufelnom-teriyake",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"15fe4ad4-4719-4f1c-bfa1-ecaf19ecbd05","MetaTags":[{"Content":"Куриное филе на гриле в трюфельном Терияке 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Куриное филе на гриле в трюфельном Терияке 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Куриное филе на гриле в трюфельном Терияке: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4aab3c9-8f46-4626-9197-ac6100889e11|169199c7-be4b-411d-90ed-7b3466bb94f4",
    path: "/product/obed-vegetarianskiy",
    meta: {"GroupID":"f4aab3c9-8f46-4626-9197-ac6100889e11","GroupLink":"","GroupTitle":"Обеденное предложение","ID":"169199c7-be4b-411d-90ed-7b3466bb94f4","MetaTags":[{"Content":"Обед Вегетарианский 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Обед Вегетарианский 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Обед Вегетарианский: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|26467268-0271-4f6f-8610-a82a009e16cb|170190bd-93f0-4092-8096-5f6c83775af5",
    path: "/product/tiramisu",
    meta: {"GroupID":"26467268-0271-4f6f-8610-a82a009e16cb","GroupLink":"/menu/deserty","GroupTitle":"Десерты","ID":"170190bd-93f0-4092-8096-5f6c83775af5","MetaTags":[{"Content":"Тирамису 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тирамису 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Тирамису: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|175ef082-be1b-401f-8618-dc9038798287",
    path: "/product/roll-s-ugrem-i-slivochnym-syrom",
    meta: {"AdditionalSeoText":"","GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"175ef082-be1b-401f-8618-dc9038798287","MetaTags":[{"Content":"Ролл с угрем и сливочным сыром  440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с угрем и сливочным сыром  440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с угрем и сливочным сыром : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|26467268-0271-4f6f-8610-a82a009e16cb|19849918-9dee-4e7a-aab8-bd427ec9bb49",
    path: "/product/chiz-keyk-klassicheskiy",
    meta: {"GroupID":"26467268-0271-4f6f-8610-a82a009e16cb","GroupLink":"/menu/deserty","GroupTitle":"Десерты","ID":"19849918-9dee-4e7a-aab8-bd427ec9bb49","MetaTags":[{"Content":"Чиз-кейк Классический 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чиз-кейк Классический 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Чиз-кейк Классический: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|e91c64eb-7e30-448a-8f70-a82a009e0f94|1ad4ea12-df54-41b8-9054-4524c6346a86",
    path: "/product/lapsha-s-kuritsey-i-ovoschami",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/lapsha-ris","GroupTitle":"Лапша / Рис","ID":"1ad4ea12-df54-41b8-9054-4524c6346a86","MetaTags":[{"Content":"Лапша с курицей и овощами 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лапша с курицей и овощами 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"e91c64eb-7e30-448a-8f70-a82a009e0f94","Title":"Лапша с курицей и овощами: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|1d45a215-4aef-413d-bfea-0395b8002d22",
    path: "/product/coca-cola",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"1d45a215-4aef-413d-bfea-0395b8002d22","MetaTags":[{"Content":"Кока-Кола 1л 150 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Кока-Кола 1л 150 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Кока-Кола 1л: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|202d0c98-9a92-482f-9948-e4200f9d01b5",
    path: "/product/salat-cezar-s-krevetkami",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"202d0c98-9a92-482f-9948-e4200f9d01b5","MetaTags":[{"Content":"Цезарь с креветками 600 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Цезарь с креветками 600 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Цезарь с креветками: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80adad0c-dd93-4c6d-805a-a82a009e13e2|2254cbd5-3b9d-481d-8a88-e47f1e84b650",
    path: "/product/slivochniy-set",
    meta: {"AdditionalSeoText":"","GroupID":"80adad0c-dd93-4c6d-805a-a82a009e13e2","GroupLink":"/menu/assorti","GroupTitle":"Ассорти","ID":"2254cbd5-3b9d-481d-8a88-e47f1e84b650","MetaTags":[{"Content":"Сэт Сливочный 2500 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Сэт Сливочный 2500 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Сэт Сливочный: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|22cde953-69c1-4ba8-9b8c-b6dd7dd5fca6",
    path: "/product/dvoynoy-losos",
    meta: {"AdditionalSeoText":"","GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"22cde953-69c1-4ba8-9b8c-b6dd7dd5fca6","MetaTags":[{"Content":"Двойной лосось 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Двойной лосось 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Двойной лосось: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|24c4a960-eed2-4fa4-bc5e-ca7a59a0df4d",
    path: "/product/kurinaya-grudka-su-vid-v-souse-teriyake-so-slivochnym-shpinatom",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"24c4a960-eed2-4fa4-bc5e-ca7a59a0df4d","MetaTags":[{"Content":"Куриная грудка Су-Вид в соусе Терияке со сливочным шпинатом 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Куриная грудка Су-Вид в соусе Терияке со сливочным шпинатом 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Куриная грудка Су-Вид в соусе Терияке со сливочным шпинатом: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|285588dc-30ce-465b-8a06-1c02bfc30355",
    path: "/product/kaliforniya-v-klyare-s-lososem",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"285588dc-30ce-465b-8a06-1c02bfc30355","MetaTags":[{"Content":"Калифорния в кляре  с лососем 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Калифорния в кляре  с лососем 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Калифорния в кляре  с лососем: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|29628540-5fc1-4848-a3c7-3b6a02712598",
    path: "/product/slivochnaya-krevetka",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"29628540-5fc1-4848-a3c7-3b6a02712598","MetaTags":[{"Content":"Сливочная креветка 440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Сливочная креветка 440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Сливочная креветка: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|2a8cfa9c-cf3a-4162-af78-a953e3148b94",
    path: "/product/utinaya-nozhka-konfi-s-kartofelnym-kremom",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"2a8cfa9c-cf3a-4162-af78-a953e3148b94","MetaTags":[{"Content":"Утиная ножка конфи с картофельным кремом 750 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Утиная ножка конфи с картофельным кремом 750 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Утиная ножка конфи с картофельным кремом: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|2bad2a13-5e43-46ac-acb1-dc303ac8745f",
    path: "/product/roll-s-krevetkoy",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"2bad2a13-5e43-46ac-acb1-dc303ac8745f","MetaTags":[{"Content":"Ролл с креветкой 250 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с креветкой 250 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с креветкой: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f63dc1f6-ee13-452a-85b2-a82a009e1262|2d2b730a-fde9-4459-84f8-6550ebc122f5",
    path: "/product/kurinyy-sup-s-lapshoy",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","GroupLink":"/menu/supy","GroupTitle":"Супы","ID":"2d2b730a-fde9-4459-84f8-6550ebc122f5","MetaTags":[{"Content":"Куриный суп с лапшой 290 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Куриный суп с лапшой 290 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Куриный суп с лапшой: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|e91c64eb-7e30-448a-8f70-a82a009e0f94|2f4e59a0-aa35-417a-b50b-9b40dea2cb55",
    path: "/product/imbirniy-ris-s-moreproduktami",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/lapsha-ris","GroupTitle":"Лапша / Рис","ID":"2f4e59a0-aa35-417a-b50b-9b40dea2cb55","MetaTags":[{"Content":"Имбирный рис  с морепродуктами 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Имбирный рис  с морепродуктами 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"e91c64eb-7e30-448a-8f70-a82a009e0f94","Title":"Имбирный рис  с морепродуктами: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|31a413df-d3bd-4a70-ac1b-d1f71e7cec95",
    path: "/product/krevetki-tempura-s-aziatskim-sousom",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"31a413df-d3bd-4a70-ac1b-d1f71e7cec95","MetaTags":[{"Content":"Креветки темпура с азиатским соусом 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Креветки темпура с азиатским соусом 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Креветки темпура с азиатским соусом: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|3317e59a-0833-4665-9d1d-7df4841d338c",
    path: "/product/roll-s-tuntsom",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"3317e59a-0833-4665-9d1d-7df4841d338c","MetaTags":[{"Content":"Ролл с тунцом  240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с тунцом  240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с тунцом : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|336771e1-3444-48d9-ac5a-e66b1e046da2",
    path: "/product/kushiyaki-krevetki-s-aziatskim-sousom",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"336771e1-3444-48d9-ac5a-e66b1e046da2","MetaTags":[{"Content":"Кушияки креветки с азиатским соусом 290 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Кушияки креветки с азиатским соусом 290 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Кушияки креветки с азиатским соусом: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|339d3871-f5e1-421e-8dee-d8a8b6cfa743",
    path: "/product/tar-tar-iz-lososya-i-tuntsa",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"339d3871-f5e1-421e-8dee-d8a8b6cfa743","MetaTags":[{"Content":"Тар-тар из лосося и тунца 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тар-тар из лосося и тунца 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Тар-тар из лосося и тунца: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|36c29a5c-0d5e-46d0-b064-4a85ced39d26",
    path: "/product/roll-s-ogurtsom",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"36c29a5c-0d5e-46d0-b064-4a85ced39d26","MetaTags":[{"Content":"Ролл с огурцом 150 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с огурцом 150 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с огурцом: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|6b2160c7-6a39-42c1-b8bf-a82a009e121b|38703198-a656-4e82-8f9a-2a7143ffc45c",
    path: "/product/sushi-ostriy-tunets",
    meta: {"GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","GroupLink":"/menu/ostrye-sushi","GroupTitle":"Острые суши","ID":"38703198-a656-4e82-8f9a-2a7143ffc45c","MetaTags":[{"Content":"Суши Острый тунец 240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суши Острый тунец 240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"6b2160c7-6a39-42c1-b8bf-a82a009e121b","Title":"Суши Острый тунец: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|38b511c8-48b7-4bce-bf31-b2d2e7454779",
    path: "/product/mentayko",
    meta: {"AdditionalSeoText":"","GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"38b511c8-48b7-4bce-bf31-b2d2e7454779","MetaTags":[{"Content":"Ментайко 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ментайко 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ментайко: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|39b1ee06-f4e3-4ef5-a3bf-2f70d8a6228e",
    path: "/product/khokkaydo",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"39b1ee06-f4e3-4ef5-a3bf-2f70d8a6228e","MetaTags":[{"Content":"Хоккайдо  690 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хоккайдо  690 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Хоккайдо : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|3a00c70a-46fd-4298-a3a0-9f46ca64a210",
    path: "/product/aqua-russa-bez-gaz",
    meta: {"AdditionalSeoText":"","GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"3a00c70a-46fd-4298-a3a0-9f46ca64a210","MetaTags":[{"Content":"Танти н/ газ 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Танти н/ газ 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Танти н/ газ: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|e91c64eb-7e30-448a-8f70-a82a009e0f94|3afd290a-d85d-4096-a229-88aeb8c99677",
    path: "/product/lapsha-s-kalmarami-i-krevetkami",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/lapsha-ris","GroupTitle":"Лапша / Рис","ID":"3afd290a-d85d-4096-a229-88aeb8c99677","MetaTags":[{"Content":"Лапша с кальмарами и креветками 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лапша с кальмарами и креветками 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"e91c64eb-7e30-448a-8f70-a82a009e0f94","Title":"Лапша с кальмарами и креветками: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4aab3c9-8f46-4626-9197-ac6100889e11|3b1e207c-33f8-4c61-9a2a-238567a2aa5d",
    path: "/product/obed-iz-moreproduktov",
    meta: {"GroupID":"f4aab3c9-8f46-4626-9197-ac6100889e11","GroupLink":"","GroupTitle":"Обеденное предложение","ID":"3b1e207c-33f8-4c61-9a2a-238567a2aa5d","MetaTags":[{"Content":"Обед из морепродуктов 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Обед из морепродуктов 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Обед из морепродуктов: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f63dc1f6-ee13-452a-85b2-a82a009e1262|3d36e318-a6f9-46b8-9cc5-bbeaac6dba4d",
    path: "/product/siyu-ramen-s-khrustyaschey-kuritsey-s",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","GroupLink":"/menu/supy","GroupTitle":"Супы","ID":"3d36e318-a6f9-46b8-9cc5-bbeaac6dba4d","MetaTags":[{"Content":"Сию рамен с хрустящей курицей  450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Сию рамен с хрустящей курицей  450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Сию рамен с хрустящей курицей : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|4244d1c6-8703-4d41-9068-8ec054f1cbba",
    path: "/product/morio",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"4244d1c6-8703-4d41-9068-8ec054f1cbba","MetaTags":[{"Content":"Морио 510 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Морио 510 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Морио: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|437e20a2-3893-4fa9-b007-e2f77e9401c4",
    path: "/product/pryaniy-baklazhan-s-penoy-iz-slivochnogo-ugrya",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"437e20a2-3893-4fa9-b007-e2f77e9401c4","MetaTags":[{"Content":"Пряный баклажан с пеной из сливочного угря 410 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пряный баклажан с пеной из сливочного угря 410 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Пряный баклажан с пеной из сливочного угря: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|46310366-2b49-4060-a80d-ebeaf4f1dcd9",
    path: "/product/zhareniy-syr-s-yagodnym-sousom",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"46310366-2b49-4060-a80d-ebeaf4f1dcd9","MetaTags":[{"Content":"Жареный сыр с ягодным соусом 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Жареный сыр с ягодным соусом 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Жареный сыр с ягодным соусом: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|46966ba0-4752-470a-bfc9-f014fb975ea0",
    path: "/product/roll-oushen",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"46966ba0-4752-470a-bfc9-f014fb975ea0","MetaTags":[{"Content":"Ролл Оушен 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл Оушен 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл Оушен: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|47c419b5-400e-42f2-b188-42e881cfaea5",
    path: "/product/mors-05",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"47c419b5-400e-42f2-b188-42e881cfaea5","MetaTags":[{"Content":"Морс клюквенный 0,3 180 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Морс клюквенный 0,3 180 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Морс клюквенный 0,3: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|49618f07-45cd-4fac-8991-8502143a5e79",
    path: "/product/49618f07-45cd-4fac-8991-8502143a5e79",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"49618f07-45cd-4fac-8991-8502143a5e79","MetaTags":[{"Content":"Лимонад малина грейпфрут 230 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лимонад малина грейпфрут 230 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Лимонад малина грейпфрут: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|4a19191b-2bee-4a28-ad81-a8b89e1e001a",
    path: "/product/4a19191b-2bee-4a28-ad81-a8b89e1e001a",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"4a19191b-2bee-4a28-ad81-a8b89e1e001a","MetaTags":[{"Content":"Горячий ролл с острым тунцом и авокадо 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Горячий ролл с острым тунцом и авокадо 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Горячий ролл с острым тунцом и авокадо: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|4df1fa19-7903-47ba-b804-d2189eb188c4",
    path: "/product/salat-s-rostbifom-khrustyaschim-baklazhanom-i-kopchenoy-smetanoy-s",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"4df1fa19-7903-47ba-b804-d2189eb188c4","MetaTags":[{"Content":"Салат с ростбифом, хрустящим баклажаном и копченой сметаной  610 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Салат с ростбифом, хрустящим баклажаном и копченой сметаной  610 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Салат с ростбифом, хрустящим баклажаном и копченой сметаной : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|4e9ff4ea-903f-4839-8bbe-616915ac078c",
    path: "/product/pizza-4-syra-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"4e9ff4ea-903f-4839-8bbe-616915ac078c","MetaTags":[{"Content":"Пицца 4 сыра 910 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца 4 сыра 910 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца 4 сыра: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|4fa6e418-c9e6-481f-856c-c76f7010fef3",
    path: "/product/kushiyaki-file-bedra",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"4fa6e418-c9e6-481f-856c-c76f7010fef3","MetaTags":[{"Content":"Кушияки филе бедра  220 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Кушияки филе бедра  220 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Кушияки филе бедра : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|26467268-0271-4f6f-8610-a82a009e16cb|50c1d6cc-aff1-4f9a-9d6d-bb3fdb792283",
    path: "/product/50c1d6cc-aff1-4f9a-9d6d-bb3fdb792283",
    meta: {"GroupID":"26467268-0271-4f6f-8610-a82a009e16cb","GroupLink":"/menu/deserty","GroupTitle":"Десерты","ID":"50c1d6cc-aff1-4f9a-9d6d-bb3fdb792283","MetaTags":[{"Content":"Кофейно-маковый чиз-кейк Сан-Себастьян 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Кофейно-маковый чиз-кейк Сан-Себастьян 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Кофейно-маковый чиз-кейк Сан-Себастьян: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|50fcc5a4-45a1-4002-9dfd-2b6f81b506f5",
    path: "/product/khrustyaschiy-kalmar-r",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"50fcc5a4-45a1-4002-9dfd-2b6f81b506f5","MetaTags":[{"Content":"Хрустящий кальмар 480 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хрустящий кальмар 480 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Хрустящий кальмар: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|534258f4-e6c1-44ee-afef-2b3802a863b2",
    path: "/product/534258f4-e6c1-44ee-afef-2b3802a863b2",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"534258f4-e6c1-44ee-afef-2b3802a863b2","MetaTags":[{"Content":"Свежие овощи с пряным соусом из Тофу 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Свежие овощи с пряным соусом из Тофу 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Свежие овощи с пряным соусом из Тофу: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|53b95a28-b6be-463b-981c-46a0b7dfff0a",
    path: "/product/roll-s-lososem-i-ogurtsom",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"53b95a28-b6be-463b-981c-46a0b7dfff0a","MetaTags":[{"Content":"Ролл с лососем и огурцом 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с лососем и огурцом 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с лососем и огурцом: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|1614f599-c2d2-4870-9c5f-a82a009e15f8|58caea50-afad-4d81-a0ef-dabdafd9b7d8",
    path: "/product/kartofel-fri",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/garniry","GroupTitle":"Гарниры","ID":"58caea50-afad-4d81-a0ef-dabdafd9b7d8","MetaTags":[{"Content":"Картофель фри 290 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Картофель фри 290 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"1614f599-c2d2-4870-9c5f-a82a009e15f8","Title":"Картофель фри: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|5a23fc6a-9496-4972-b021-c4a5af814a5f",
    path: "/product/aziatskaya-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"5a23fc6a-9496-4972-b021-c4a5af814a5f","MetaTags":[{"Content":"Азиатская  790 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Азиатская  790 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Азиатская : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|5ce114d6-0ff2-40a2-b5ff-a1474f76347c",
    path: "/product/5ce114d6-0ff2-40a2-b5ff-a1474f76347c",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"5ce114d6-0ff2-40a2-b5ff-a1474f76347c","MetaTags":[{"Content":"Пицца с ростбифом и вялеными томатами 930 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца с ростбифом и вялеными томатами 930 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца с ростбифом и вялеными томатами: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7945f4a1-9a8c-4544-9718-a82a009e163f|61c86aa4-5768-4649-a755-32d5948f713c",
    path: "/product/sushi-losos",
    meta: {"GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","GroupLink":"/menu/sushi","GroupTitle":"Классические суши","ID":"61c86aa4-5768-4649-a755-32d5948f713c","MetaTags":[{"Content":"Суши Лосось 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суши Лосось 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"7945f4a1-9a8c-4544-9718-a82a009e163f","Title":"Суши Лосось: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|63f2737d-8a72-4c65-b2d2-38d535d2a02d",
    path: "/product/salat-cezar-s-kuricey",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"63f2737d-8a72-4c65-b2d2-38d535d2a02d","MetaTags":[{"Content":"Цезарь с курицей 530 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Цезарь с курицей 530 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Цезарь с курицей: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|65ea0b21-d57c-4000-82d8-b8cb8a70a910",
    path: "/product/tempura-s-orekhovym-sousom",
    meta: {"AdditionalSeoText":"","GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"65ea0b21-d57c-4000-82d8-b8cb8a70a910","MetaTags":[{"Content":"Темпура с ореховым соусом  440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Темпура с ореховым соусом  440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Темпура с ореховым соусом : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|66ad81d6-5d5c-49d8-a2af-0e6dc390dfbe",
    path: "/product/pizza-s-krevekami-i-tsukini-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"66ad81d6-5d5c-49d8-a2af-0e6dc390dfbe","MetaTags":[{"Content":"Пицца с креветками и цукини 890 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца с креветками и цукини 890 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца с креветками и цукини: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|6a5f2d42-97f3-4b26-851c-6964af9c39de",
    path: "/product/tomlenyye-telyachi-schechki-s-tryufelnym-pyure",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"6a5f2d42-97f3-4b26-851c-6964af9c39de","MetaTags":[{"Content":"Томленые телячьи щечки с трюфельным пюре 890 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Томленые телячьи щечки с трюфельным пюре 890 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Томленые телячьи щечки с трюфельным пюре: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|1614f599-c2d2-4870-9c5f-a82a009e15f8|6be672b3-f912-4980-afa0-8240bf2fb37b",
    path: "/product/kartofelnoe-pyure",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/garniry","GroupTitle":"Гарниры","ID":"6be672b3-f912-4980-afa0-8240bf2fb37b","MetaTags":[{"Content":"Картофельное пюре 220 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Картофельное пюре 220 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"1614f599-c2d2-4870-9c5f-a82a009e15f8","Title":"Картофельное пюре: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|6c457902-75c3-4e39-944e-e39c64e8f464",
    path: "/product/vetchina-griby-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"6c457902-75c3-4e39-944e-e39c64e8f464","MetaTags":[{"Content":"Ветчина-грибы 670 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ветчина-грибы 670 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Ветчина-грибы: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|26467268-0271-4f6f-8610-a82a009e16cb|6dadd666-75d4-43ce-bb7d-c431c72b9731",
    path: "/product/6dadd666-75d4-43ce-bb7d-c431c72b9731",
    meta: {"GroupID":"26467268-0271-4f6f-8610-a82a009e16cb","GroupLink":"/menu/deserty","GroupTitle":"Десерты","ID":"6dadd666-75d4-43ce-bb7d-c431c72b9731","MetaTags":[{"Content":"Классический Сан Себастьян 430 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Классический Сан Себастьян 430 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Классический Сан Себастьян: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f63dc1f6-ee13-452a-85b2-a82a009e1262|7017ae37-3a36-4097-a9e4-3ea060fdf361",
    path: "/product/tom-yam-kung",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","GroupLink":"/menu/supy","GroupTitle":"Супы","ID":"7017ae37-3a36-4097-a9e4-3ea060fdf361","MetaTags":[{"Content":"Том Ям Кунг  630 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Том Ям Кунг  630 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Том Ям Кунг : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|743aad87-cfcd-408b-aacd-ce08ea9f52f4",
    path: "/product/743aad87-cfcd-408b-aacd-ce08ea9f52f4",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"743aad87-cfcd-408b-aacd-ce08ea9f52f4","MetaTags":[{"Content":"Ролл с угрем и тунцом  тар-тар 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с угрем и тунцом  тар-тар 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с угрем и тунцом  тар-тар: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|76ac5ce4-50ee-46d8-aa12-d88b5d3d93a7",
    path: "/product/goryachey-kalifornii-s-krevetkami",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"76ac5ce4-50ee-46d8-aa12-d88b5d3d93a7","MetaTags":[{"Content":"Калифорния в кляре с креветками 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Калифорния в кляре с креветками 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Калифорния в кляре с креветками: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|776abd54-55e7-407a-8d78-68c6be755c49",
    path: "/product/margarita-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"776abd54-55e7-407a-8d78-68c6be755c49","MetaTags":[{"Content":"Маргарита  550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Маргарита  550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Маргарита : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|78028657-c9f5-45f0-88a3-13b5e47128b8",
    path: "/product/roll-slivochnyy-ugor",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"78028657-c9f5-45f0-88a3-13b5e47128b8","MetaTags":[{"Content":"Сливочный Угорь 580 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Сливочный Угорь 580 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Сливочный Угорь: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80adad0c-dd93-4c6d-805a-a82a009e13e2|7a463c48-670a-4645-bc47-68eba49f36cb",
    path: "/product/set-1",
    meta: {"GroupID":"80adad0c-dd93-4c6d-805a-a82a009e13e2","GroupLink":"/menu/assorti","GroupTitle":"Ассорти","ID":"7a463c48-670a-4645-bc47-68eba49f36cb","MetaTags":[{"Content":"Сэт 1 1480 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Сэт 1 1480 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Сэт 1: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|7bc4d085-6883-4c36-8a4d-738b260af0a2",
    path: "/product/7bc4d085-6883-4c36-8a4d-738b260af0a2",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"7bc4d085-6883-4c36-8a4d-738b260af0a2","MetaTags":[{"Content":"Калифорния в кляре с креветками 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Калифорния в кляре с креветками 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Калифорния в кляре с креветками: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|7e66193b-e30b-49fc-9ac4-08ad79ff9ee3",
    path: "/product/yagodniy-bananoviy-smuzi",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"7e66193b-e30b-49fc-9ac4-08ad79ff9ee3","MetaTags":[{"Content":"Ягодно-банановый смузи 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ягодно-банановый смузи 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Ягодно-банановый смузи: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|81329318-deb2-4ab2-9318-bda0fb003f42",
    path: "/product/losos-so-slivochnym-syrom-i-avokado-r",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"81329318-deb2-4ab2-9318-bda0fb003f42","MetaTags":[{"Content":"Лосось  со сливочным сыром и авокадо 500 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лосось  со сливочным сыром и авокадо 500 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Лосось  со сливочным сыром и авокадо: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|819b3071-3956-4218-b2d1-0a7195a203ef",
    path: "/product/kaliforniya-s-lososem",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"819b3071-3956-4218-b2d1-0a7195a203ef","MetaTags":[{"Content":"Калифорния с лососем 530 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Калифорния с лососем 530 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Калифорния с лососем: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|84b5784b-007c-4f39-8545-8e6ef61f596d",
    path: "/product/kurinaya-kotleta-s-mussom-iz-zharenogo-kartofelya",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"84b5784b-007c-4f39-8545-8e6ef61f596d","MetaTags":[{"Content":"Куриная котлета с картофельным пюре и шпинатом 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Куриная котлета с картофельным пюре и шпинатом 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Куриная котлета с картофельным пюре и шпинатом: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|84f4b52b-78a0-482b-8041-7ccd26011a5c",
    path: "/product/kushiyaki-ovoschi-na-grile",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"84f4b52b-78a0-482b-8041-7ccd26011a5c","MetaTags":[{"Content":"Кушияки овощи на гриле 250 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Кушияки овощи на гриле 250 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Кушияки овощи на гриле: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|85e4581e-8708-44df-84d6-9ef3da2a6e7d",
    path: "/product/kranch-roll-r",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"85e4581e-8708-44df-84d6-9ef3da2a6e7d","MetaTags":[{"Content":"Кранч ролл 530 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Кранч ролл 530 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Кранч ролл: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|85ef547d-89f1-44e8-8dd2-3f9998528f43",
    path: "/product/aqua-russa-gaz",
    meta: {"AdditionalSeoText":"","GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"85ef547d-89f1-44e8-8dd2-3f9998528f43","MetaTags":[{"Content":"Татни газ 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Татни газ 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Татни газ: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|26467268-0271-4f6f-8610-a82a009e16cb|86f0ddb0-9722-4541-9eb1-992e3e1ddb14",
    path: "/product/86f0ddb0-9722-4541-9eb1-992e3e1ddb14",
    meta: {"GroupID":"26467268-0271-4f6f-8610-a82a009e16cb","GroupLink":"/menu/deserty","GroupTitle":"Десерты","ID":"86f0ddb0-9722-4541-9eb1-992e3e1ddb14","MetaTags":[{"Content":"Моти пралине-финик 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Моти пралине-финик 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Моти пралине-финик: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|e91c64eb-7e30-448a-8f70-a82a009e0f94|87f2da59-6f23-43c5-8b7e-0fbef971cc6c",
    path: "/product/lapsha-s-ovoschami-v-soevo-imbirnom-souse",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/lapsha-ris","GroupTitle":"Лапша / Рис","ID":"87f2da59-6f23-43c5-8b7e-0fbef971cc6c","MetaTags":[{"Content":"Лапша с овощами в соево-имбирном соусе 400 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лапша с овощами в соево-имбирном соусе 400 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"e91c64eb-7e30-448a-8f70-a82a009e0f94","Title":"Лапша с овощами в соево-имбирном соусе: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|e91c64eb-7e30-448a-8f70-a82a009e0f94|88aac324-a0ef-425e-a818-16b21acfb902",
    path: "/product/lapsha-so-svininoy-i-ovoschami-v-ustrichno-laymovom-souse",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/lapsha-ris","GroupTitle":"Лапша / Рис","ID":"88aac324-a0ef-425e-a818-16b21acfb902","MetaTags":[{"Content":"Лапша со свининой и овощами в устрично-лаймовом соусе 390 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лапша со свининой и овощами в устрично-лаймовом соусе 390 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"e91c64eb-7e30-448a-8f70-a82a009e0f94","Title":"Лапша со свининой и овощами в устрично-лаймовом соусе: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|89a92826-0fee-46f4-b3c5-eafac1c4243a",
    path: "/product/zapechenaya-brokkoli-s-orekhovym-sousom",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"89a92826-0fee-46f4-b3c5-eafac1c4243a","MetaTags":[{"Content":"Запеченая Брокколи с ореховым соусом 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Запеченая Брокколи с ореховым соусом 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Запеченая Брокколи с ореховым соусом: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|8a151ca8-e379-4762-b25b-aa88acca0660",
    path: "/product/svezhie-ovoschi-s-pryanym-sousom-iz-tofu",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"8a151ca8-e379-4762-b25b-aa88acca0660","MetaTags":[{"Content":"Свежие овощи с пряным соусом из Тофу 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Свежие овощи с пряным соусом из Тофу 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Свежие овощи с пряным соусом из Тофу: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|8b0b9c65-2ded-4009-9469-404fbebc95c8",
    path: "/product/govyazhya-vyrezka-na-rise-s-sousom-miso",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"8b0b9c65-2ded-4009-9469-404fbebc95c8","MetaTags":[{"Content":"Говяжья вырезка на рисе с соусом мисо  830 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Говяжья вырезка на рисе с соусом мисо  830 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Говяжья вырезка на рисе с соусом мисо : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|8b5fc766-f65d-484e-9475-575b19178fd6",
    path: "/product/roll-s-avokado",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"8b5fc766-f65d-484e-9475-575b19178fd6","MetaTags":[{"Content":"Ролл с авокадо 200 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с авокадо 200 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с авокадо: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|8b68659d-9941-4e5c-aa11-6ef8b558fbbf",
    path: "/product/mortadella-strachatella-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"8b68659d-9941-4e5c-aa11-6ef8b558fbbf","MetaTags":[{"Content":"Мортаделла-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мортаделла-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Мортаделла-страчателла: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|8b77e5eb-9e0e-4074-99a9-973259e3dc98",
    path: "/product/roll-s-ugrem-i-lososem",
    meta: {"AdditionalSeoText":"","GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"8b77e5eb-9e0e-4074-99a9-973259e3dc98","MetaTags":[{"Content":"Ролл с угрем и лососем 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с угрем и лососем 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с угрем и лососем: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|8dcdfa20-51f5-4ef9-9057-c269867e4848",
    path: "/product/roll-tar-tar-losos-r",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"8dcdfa20-51f5-4ef9-9057-c269867e4848","MetaTags":[{"Content":"Ролл тар-тар лосось 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл тар-тар лосось 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл тар-тар лосось: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|92b607f0-1cab-4c40-a5f9-8c25d89fb870",
    path: "/product/sok-v-ass",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"92b607f0-1cab-4c40-a5f9-8c25d89fb870","MetaTags":[{"Content":"Сок в ассортименте 1 литр 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Сок в ассортименте 1 литр 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Сок в ассортименте 1 литр: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|63f46009-62f4-4fee-bab4-a82a009e0fe4|94b5f11f-2ef5-49cb-92c1-83d34d3a3cce",
    path: "/product/94b5f11f-2ef5-49cb-92c1-83d34d3a3cce",
    meta: {"GroupID":"63f46009-62f4-4fee-bab4-a82a009e0fe4","GroupLink":"/menu/detskoe-menyu","GroupTitle":"Детское меню","ID":"94b5f11f-2ef5-49cb-92c1-83d34d3a3cce","MetaTags":[{"Content":"Чиккен Бенто детский 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чиккен Бенто детский 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Чиккен Бенто детский: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|1614f599-c2d2-4870-9c5f-a82a009e15f8|9515e436-9fdd-4681-b42a-533726fa89e0",
    path: "/product/ris-gohan",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/garniry","GroupTitle":"Гарниры","ID":"9515e436-9fdd-4681-b42a-533726fa89e0","MetaTags":[{"Content":"Рис Гохан 120 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Рис Гохан 120 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"1614f599-c2d2-4870-9c5f-a82a009e15f8","Title":"Рис Гохан: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|9d4b7198-2699-4c16-9d4a-fea25bc0e816",
    path: "/product/salat-so-sladkimi-tomatami-i-baklazhanom-tempura-s",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"9d4b7198-2699-4c16-9d4a-fea25bc0e816","MetaTags":[{"Content":"Салат со сладкими томатами и баклажаном темпура 470 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Салат со сладкими томатами и баклажаном темпура 470 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Салат со сладкими томатами и баклажаном темпура: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|26467268-0271-4f6f-8610-a82a009e16cb|9e811c1d-fbc1-4843-9bba-b477fd14c8c6",
    path: "/product/ekler-v-assortimente",
    meta: {"GroupID":"26467268-0271-4f6f-8610-a82a009e16cb","GroupLink":"/menu/deserty","GroupTitle":"Десерты","ID":"9e811c1d-fbc1-4843-9bba-b477fd14c8c6","MetaTags":[{"Content":"Эклер в ассортименте 190 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Эклер в ассортименте 190 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Эклер в ассортименте: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|a083b09e-a2e0-492d-967b-a9be288893bb",
    path: "/product/gastro-roll",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"a083b09e-a2e0-492d-967b-a9be288893bb","MetaTags":[{"Content":"Гастро-ролл 690 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Гастро-ролл 690 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Гастро-ролл: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|a217f914-fecc-46b1-859b-10a7c27d7078",
    path: "/product/khrustyaschiy-roll-s-ugrem-lososem-i-krevetkoy",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"a217f914-fecc-46b1-859b-10a7c27d7078","MetaTags":[{"Content":"Хрустящий ролл с тунцом, угрем и креветкой 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хрустящий ролл с тунцом, угрем и креветкой 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Хрустящий ролл с тунцом, угрем и креветкой: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|a230e3f7-cf6f-4325-bc0a-8f0129789290",
    path: "/product/mramornaya-govyadina-na-grile-s-veshenkami",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"a230e3f7-cf6f-4325-bc0a-8f0129789290","MetaTags":[{"Content":"Мраморная говядина на гриле с вешенками 890 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мраморная говядина на гриле с вешенками 890 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Мраморная говядина на гриле с вешенками: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|63f46009-62f4-4fee-bab4-a82a009e0fe4|a2a19ea2-9e7f-4b5f-8a57-9e70a96e17fc",
    path: "/product/tori-bento",
    meta: {"GroupID":"63f46009-62f4-4fee-bab4-a82a009e0fe4","GroupLink":"/menu/detskoe-menyu","GroupTitle":"Детское меню","ID":"a2a19ea2-9e7f-4b5f-8a57-9e70a96e17fc","MetaTags":[{"Content":"Тори Бенто 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тори Бенто 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Тори Бенто: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|a4197efd-5162-4d35-8be0-f5b228b14090",
    path: "/product/salat-chuka",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"a4197efd-5162-4d35-8be0-f5b228b14090","MetaTags":[{"Content":"Чука 430 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чука 430 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Чука: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80adad0c-dd93-4c6d-805a-a82a009e13e2|a8072f43-ecc2-448c-b958-89c476400202",
    path: "/product/assorti-miks",
    meta: {"AdditionalSeoText":"","GroupID":"80adad0c-dd93-4c6d-805a-a82a009e13e2","GroupLink":"/menu/assorti","GroupTitle":"Ассорти","ID":"a8072f43-ecc2-448c-b958-89c476400202","MetaTags":[{"Content":"Ассорти Микс 2650 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ассорти Микс 2650 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Ассорти Микс: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|ec5845bd-4e70-41fa-84a6-b08a00e8badd|a8aa5fe1-386e-4130-a0eb-0f86433f7e82",
    path: "/product/konstrktor-polovinok",
    meta: {"AdditionalSeoText":"","GroupID":"ec5845bd-4e70-41fa-84a6-b08a00e8badd","GroupLink":"","GroupTitle":"Конструктор половинок","ID":"a8aa5fe1-386e-4130-a0eb-0f86433f7e82","MetaTags":[{"Content":"Констрктор половинок  0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Констрктор половинок  0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Констрктор половинок : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|ab23a1a4-8a0d-4ca9-afb6-4e8d92212ba9",
    path: "/product/losos-s-tryufelnym-syrom",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"ab23a1a4-8a0d-4ca9-afb6-4e8d92212ba9","MetaTags":[{"Content":"Лосось  с трюфельным сыром  0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лосось  с трюфельным сыром  0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Лосось  с трюфельным сыром : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4aab3c9-8f46-4626-9197-ac6100889e11|ac49ca79-4a2d-453f-ad67-49f8022866b9",
    path: "/product/obed-iz-govyadiny",
    meta: {"GroupID":"f4aab3c9-8f46-4626-9197-ac6100889e11","GroupLink":"","GroupTitle":"Обеденное предложение","ID":"ac49ca79-4a2d-453f-ad67-49f8022866b9","MetaTags":[{"Content":"Обед из говядины 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Обед из говядины 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Обед из говядины: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|ac5a9693-4428-48ef-b787-12be61cc3c7b",
    path: "/product/roll-filadelfiya",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"ac5a9693-4428-48ef-b787-12be61cc3c7b","MetaTags":[{"Content":"Филадельфия 620 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Филадельфия 620 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Филадельфия: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|63f46009-62f4-4fee-bab4-a82a009e0fe4|aec47238-8a82-4b9f-a1ea-3df1ad2b68e0",
    path: "/product/aec47238-8a82-4b9f-a1ea-3df1ad2b68e0",
    meta: {"GroupID":"63f46009-62f4-4fee-bab4-a82a009e0fe4","GroupLink":"/menu/detskoe-menyu","GroupTitle":"Детское меню","ID":"aec47238-8a82-4b9f-a1ea-3df1ad2b68e0","MetaTags":[{"Content":"Тори Бенто детский 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тори Бенто детский 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Тори Бенто детский: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|b0845fa3-fd55-4fef-b181-16c20f3148dc",
    path: "/product/pepperoni-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"b0845fa3-fd55-4fef-b181-16c20f3148dc","MetaTags":[{"Content":"Пепперони 690 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пепперони 690 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пепперони: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|26467268-0271-4f6f-8610-a82a009e16cb|b0ea3388-32df-4a57-b868-9273f5e385fa",
    path: "/product/shtrudel-yablochniy",
    meta: {"GroupID":"26467268-0271-4f6f-8610-a82a009e16cb","GroupLink":"/menu/deserty","GroupTitle":"Десерты","ID":"b0ea3388-32df-4a57-b868-9273f5e385fa","MetaTags":[{"Content":"Штрудель яблочный 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Штрудель яблочный 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Штрудель яблочный: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7945f4a1-9a8c-4544-9718-a82a009e163f|b59ee816-6f7f-41e1-84a7-b2977962a618",
    path: "/product/sushi-krevetka",
    meta: {"GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","GroupLink":"/menu/sushi","GroupTitle":"Классические суши","ID":"b59ee816-6f7f-41e1-84a7-b2977962a618","MetaTags":[{"Content":"Суши Креветка 240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суши Креветка 240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"7945f4a1-9a8c-4544-9718-a82a009e163f","Title":"Суши Креветка: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|e91c64eb-7e30-448a-8f70-a82a009e0f94|b68e66c5-f30c-4d0e-be43-d93016db83c6",
    path: "/product/zhareniy-ris-s-kuritsey-i-ovoschami",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/lapsha-ris","GroupTitle":"Лапша / Рис","ID":"b68e66c5-f30c-4d0e-be43-d93016db83c6","MetaTags":[{"Content":"Жареный рис с курицей и овощами 430 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Жареный рис с курицей и овощами 430 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"e91c64eb-7e30-448a-8f70-a82a009e0f94","Title":"Жареный рис с курицей и овощами: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|b82e29d3-7344-489c-8c78-ce4f65d14194",
    path: "/product/roll-ostriy-losos",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"b82e29d3-7344-489c-8c78-ce4f65d14194","MetaTags":[{"Content":"Ролл Острый лосось 440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл Острый лосось 440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл Острый лосось: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|bb87fa8f-6a35-487b-ad67-a2f8745fef8f",
    path: "/product/chuka-salat",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"bb87fa8f-6a35-487b-ad67-a2f8745fef8f","MetaTags":[{"Content":"Чука салат 430 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чука салат 430 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Чука салат: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|bc133c89-77ae-46a8-a34e-6f2316823098",
    path: "/product/roll-blek-aut",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"bc133c89-77ae-46a8-a34e-6f2316823098","MetaTags":[{"Content":"Блэк Аут 440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Блэк Аут 440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Блэк Аут: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|beefad00-f6e0-467f-b610-58d140533206",
    path: "/product/beefad00-f6e0-467f-b610-58d140533206",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"beefad00-f6e0-467f-b610-58d140533206","MetaTags":[{"Content":"Мохито безалкогольный 0,3 180 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мохито безалкогольный 0,3 180 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Мохито безалкогольный 0,3: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|6b2160c7-6a39-42c1-b8bf-a82a009e121b|bf033d34-6bac-46b2-9514-d9ede12f582f",
    path: "/product/spays-sushi-unagi",
    meta: {"AdditionalSeoText":"","GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","GroupLink":"/menu/ostrye-sushi","GroupTitle":"Острые суши","ID":"bf033d34-6bac-46b2-9514-d9ede12f582f","MetaTags":[{"Content":"Суши Острый Угорь 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суши Острый Угорь 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"6b2160c7-6a39-42c1-b8bf-a82a009e121b","Title":"Суши Острый Угорь: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|bff6cf78-7bc6-4bb6-bd40-4a40738a3ecd",
    path: "/product/pryaniy-tsyplenok-s-drevesnymi-gribami-i-zelenym-salatom",
    meta: {"AdditionalSeoText":"","GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"bff6cf78-7bc6-4bb6-bd40-4a40738a3ecd","MetaTags":[{"Content":"Пряный цыпленок с древесными грибами и зеленым салатом  520 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пряный цыпленок с древесными грибами и зеленым салатом  520 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Пряный цыпленок с древесными грибами и зеленым салатом : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f63dc1f6-ee13-452a-85b2-a82a009e1262|c09edae1-2915-4a1a-9ed8-1fd6de1d245b",
    path: "/product/miso-sup-s-lososem-i-gribami",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","GroupLink":"/menu/supy","GroupTitle":"Супы","ID":"c09edae1-2915-4a1a-9ed8-1fd6de1d245b","MetaTags":[{"Content":"Мисо суп с лососем и грибами 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мисо суп с лососем и грибами 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Мисо суп с лососем и грибами: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|c225e79f-44ee-4ce7-a5d1-4da856733c24",
    path: "/product/c225e79f-44ee-4ce7-a5d1-4da856733c24",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"c225e79f-44ee-4ce7-a5d1-4da856733c24","MetaTags":[{"Content":"Лимонад черника, личи, лемонграс 230 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лимонад черника, личи, лемонграс 230 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Лимонад черника, личи, лемонграс: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|c2d9b33d-7431-4942-b5c1-5ad618413875",
    path: "/product/khrustyaschiy-baklazhan-s-govyadinoy-tataki",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"c2d9b33d-7431-4942-b5c1-5ad618413875","MetaTags":[{"Content":"Хрустящий баклажан  с говядиной-татаки  610 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хрустящий баклажан  с говядиной-татаки  610 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Хрустящий баклажан  с говядиной-татаки : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b|c4036ea3-7c7f-4306-b842-d65f3d6656b9",
    path: "/product/kombo-1",
    meta: {"GroupID":"139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b","GroupLink":"/menu/kombo","GroupTitle":"КОМБО","ID":"c4036ea3-7c7f-4306-b842-d65f3d6656b9","MetaTags":[{"Content":"Комбо 1 800 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Комбо 1 800 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Комбо 1: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|c4b69b20-d967-4756-ad28-aec0604c6a99",
    path: "/product/grusha-gorgondzolla-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"c4b69b20-d967-4756-ad28-aec0604c6a99","MetaTags":[{"Content":"Груша - горгондзолла 730 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Груша - горгондзолла 730 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Груша - горгондзолла: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|c4c5a1d8-ef5b-400e-8374-af02f1dd4e5b",
    path: "/product/roll-slivochniy-ugor-r",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"c4c5a1d8-ef5b-400e-8374-af02f1dd4e5b","MetaTags":[{"Content":"Ролл сливочный угорь 580 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл сливочный угорь 580 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл сливочный угорь: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|c4df28be-fa18-48ff-990c-6d45c594b0a3",
    path: "/product/roll-s-ugrem-i-krevetkoy-r",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"c4df28be-fa18-48ff-990c-6d45c594b0a3","MetaTags":[{"Content":"Ролл с угрем и креветкой  590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с угрем и креветкой  590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с угрем и креветкой : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|c4fb9526-d3ab-409b-870b-6d631dae64e1",
    path: "/product/krevetki-na-grile",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"c4fb9526-d3ab-409b-870b-6d631dae64e1","MetaTags":[{"Content":"Креветки на гриле 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Креветки на гриле 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Креветки на гриле: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|c58982d9-fc68-45f5-a8b1-09def7628053",
    path: "/product/roll-s-lososem",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"c58982d9-fc68-45f5-a8b1-09def7628053","MetaTags":[{"Content":"Ролл с лососем 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с лососем 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с лососем: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|c66b2a88-2b8f-4c26-a026-7f798b74d635",
    path: "/product/kuritsa-s-ovoschami-v-souse-teriyake",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"c66b2a88-2b8f-4c26-a026-7f798b74d635","MetaTags":[{"Content":"Курица с овощами в соусе Терияке 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Курица с овощами в соусе Терияке 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Курица с овощами в соусе Терияке: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|c6725f8f-f096-472d-b38d-eca96b6df9f7",
    path: "/product/salat-s-krevetkami-i-avokado-s",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"c6725f8f-f096-472d-b38d-eca96b6df9f7","MetaTags":[{"Content":"Салат с креветками и авокадо  610 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Салат с креветками и авокадо  610 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Салат с креветками и авокадо : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|c6b6eeba-d518-4f24-833a-4932e93ff2f3",
    path: "/product/c6b6eeba-d518-4f24-833a-4932e93ff2f3",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"c6b6eeba-d518-4f24-833a-4932e93ff2f3","MetaTags":[{"Content":"Ролл Темпура с тунцом и сливочным сыром 470 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл Темпура с тунцом и сливочным сыром 470 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Ролл Темпура с тунцом и сливочным сыром: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|c742679f-b06c-43d2-a9f1-42dbbe56cd0f",
    path: "/product/pizza-tsezar-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"c742679f-b06c-43d2-a9f1-42dbbe56cd0f","MetaTags":[{"Content":"Пицца Цезарь 730 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца Цезарь 730 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца Цезарь: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|c8741a01-e63f-4d1c-bf5e-e08100ffdeed",
    path: "/product/tunets-tataki-s-orekhovym-kremom-s",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"c8741a01-e63f-4d1c-bf5e-e08100ffdeed","MetaTags":[{"Content":"Тунец татаки с ореховым кремом  590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тунец татаки с ореховым кремом  590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Тунец татаки с ореховым кремом : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|63f46009-62f4-4fee-bab4-a82a009e0fe4|ca89893e-b44d-4b53-a50a-6a408d693f1c",
    path: "/product/chikken-bento",
    meta: {"AdditionalSeoText":"","GroupID":"63f46009-62f4-4fee-bab4-a82a009e0fe4","GroupLink":"/menu/detskoe-menyu","GroupTitle":"Детское меню","ID":"ca89893e-b44d-4b53-a50a-6a408d693f1c","MetaTags":[{"Content":"Чиккен Бенто 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Чиккен Бенто 590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":null,"Title":"Чиккен Бенто: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|cbad47dd-5c07-42d1-9312-e99b43bad072",
    path: "/product/krispi-roll",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"cbad47dd-5c07-42d1-9312-e99b43bad072","MetaTags":[{"Content":"Криспи ролл 610 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Криспи ролл 610 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Криспи ролл: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f63dc1f6-ee13-452a-85b2-a82a009e1262|ce1d79c7-b9b4-49b9-9ec5-ce021f0b758a",
    path: "/product/ce1d79c7-b9b4-49b9-9ec5-ce021f0b758a",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","GroupLink":"/menu/supy","GroupTitle":"Супы","ID":"ce1d79c7-b9b4-49b9-9ec5-ce021f0b758a","MetaTags":[{"Content":"Крем-суп тыквенный с кокосовым молоком 390 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Крем-суп тыквенный с кокосовым молоком 390 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Крем-суп тыквенный с кокосовым молоком: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4aab3c9-8f46-4626-9197-ac6100889e11|cf1e90e9-d87f-46ef-bcd7-18074548069f",
    path: "/product/obed-iz-kuritsy",
    meta: {"GroupID":"f4aab3c9-8f46-4626-9197-ac6100889e11","GroupLink":"","GroupTitle":"Обеденное предложение","ID":"cf1e90e9-d87f-46ef-bcd7-18074548069f","MetaTags":[{"Content":"Обед из курицы 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Обед из курицы 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Обед из курицы: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|cf296625-3c77-45c5-a78a-972936b94386",
    path: "/product/niyama",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"cf296625-3c77-45c5-a78a-972936b94386","MetaTags":[{"Content":"Нияма 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Нияма 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Нияма: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|d16d868f-2dd1-466c-9295-a11fdbbcf7c0",
    path: "/product/khrustyaschiy-kalmar",
    meta: {"AdditionalSeoText":"","GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"d16d868f-2dd1-466c-9295-a11fdbbcf7c0","MetaTags":[{"Content":"Хрустящий кальмар 480 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хрустящий кальмар 480 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Хрустящий кальмар: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f63dc1f6-ee13-452a-85b2-a82a009e1262|d27b23f5-805f-4952-a006-00b99f41caa3",
    path: "/product/siyu-ramen-s-govyadinoy-s",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","GroupLink":"/menu/supy","GroupTitle":"Супы","ID":"d27b23f5-805f-4952-a006-00b99f41caa3","MetaTags":[{"Content":"Сию рамен с говядиной  490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Сию рамен с говядиной  490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Сию рамен с говядиной : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|d4125da8-dd32-4210-b64f-81035cf96c59",
    path: "/product/kushiyaki-losos-v-souse-teriyake",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"d4125da8-dd32-4210-b64f-81035cf96c59","MetaTags":[{"Content":"Кушияки лосось в соусе Терияке 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Кушияки лосось в соусе Терияке 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Кушияки лосось в соусе Терияке: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|d4cdae74-4867-4d61-81ff-fa48a2acf360",
    path: "/product/roll-s-lososem-i-krevetkoy",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"d4cdae74-4867-4d61-81ff-fa48a2acf360","MetaTags":[{"Content":"Ролл с лососем и креветкой  590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с лососем и креветкой  590 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с лососем и креветкой : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7945f4a1-9a8c-4544-9718-a82a009e163f|d4e30627-b49f-456f-b360-4d8915731e18",
    path: "/product/sushi-tunets",
    meta: {"GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","GroupLink":"/menu/sushi","GroupTitle":"Классические суши","ID":"d4e30627-b49f-456f-b360-4d8915731e18","MetaTags":[{"Content":"Суши Тунец 240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суши Тунец 240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"7945f4a1-9a8c-4544-9718-a82a009e163f","Title":"Суши Тунец: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|d577bdb4-2305-4bde-852b-022a3959126d",
    path: "/product/d577bdb4-2305-4bde-852b-022a3959126d",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"d577bdb4-2305-4bde-852b-022a3959126d","MetaTags":[{"Content":"Ролл с угрем, лососем и креветками 650 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с угрем, лососем и креветками 650 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с угрем, лососем и креветками: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|63f46009-62f4-4fee-bab4-a82a009e0fe4|d8f2e0c4-5b25-43cb-b504-6a816d75621b",
    path: "/product/d8f2e0c4-5b25-43cb-b504-6a816d75621b",
    meta: {"GroupID":"63f46009-62f4-4fee-bab4-a82a009e0fe4","GroupLink":"/menu/detskoe-menyu","GroupTitle":"Детское меню","ID":"d8f2e0c4-5b25-43cb-b504-6a816d75621b","MetaTags":[{"Content":"Салат из свежих овощей 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Салат из свежих овощей 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Салат из свежих овощей: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|d975639c-99ec-4aba-b5d5-4da209d5d16a",
    path: "/product/roll-tryufelniy-tunets",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"d975639c-99ec-4aba-b5d5-4da209d5d16a","MetaTags":[{"Content":"Ролл Трюфельный тунец 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл Трюфельный тунец 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл Трюфельный тунец: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|db6aa108-39b9-4936-ab35-4af0a4fc2319",
    path: "/product/utinaya-grudka-su-vid",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"db6aa108-39b9-4936-ab35-4af0a4fc2319","MetaTags":[{"Content":"Утиная грудка Су-Вид  0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Утиная грудка Су-Вид  0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Утиная грудка Су-Вид : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|dbc75e24-faf0-406e-b079-71d221cf91b8",
    path: "/product/dbc75e24-faf0-406e-b079-71d221cf91b8",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"dbc75e24-faf0-406e-b079-71d221cf91b8","MetaTags":[{"Content":"\"Белый\" 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"\"Белый\" 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"\"Белый\": доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|dc6a5903-8858-47dc-be0d-a330105b5a0c",
    path: "/product/myasnoe-assorti-p",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"dc6a5903-8858-47dc-be0d-a330105b5a0c","MetaTags":[{"Content":"Мясное ассорти  790 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мясное ассорти  790 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Мясное ассорти : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f63dc1f6-ee13-452a-85b2-a82a009e1262|ddd145b6-d13d-4073-a398-f72bdd0d370f",
    path: "/product/ddd145b6-d13d-4073-a398-f72bdd0d370f",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","GroupLink":"/menu/supy","GroupTitle":"Супы","ID":"ddd145b6-d13d-4073-a398-f72bdd0d370f","MetaTags":[{"Content":"Сию рамен с говядиной  490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Сию рамен с говядиной  490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Сию рамен с говядиной : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b|e57625ef-eeef-4f3a-a611-d5073fb8dd7d",
    path: "/product/kombo-3",
    meta: {"GroupID":"139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b","GroupLink":"/menu/kombo","GroupTitle":"КОМБО","ID":"e57625ef-eeef-4f3a-a611-d5073fb8dd7d","MetaTags":[{"Content":"Комбо 3 1790 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Комбо 3 1790 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Комбо 3: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|e612ee08-9efe-485e-9e5f-e47be49dcad7",
    path: "/product/khitachi",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"e612ee08-9efe-485e-9e5f-e47be49dcad7","MetaTags":[{"Content":"Хитачи 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хитачи 550 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Хитачи: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|6b2160c7-6a39-42c1-b8bf-a82a009e121b|e8768d3c-df2d-42aa-a6fd-71a22ad253e0",
    path: "/product/sushi-ostraya-krevetka",
    meta: {"GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","GroupLink":"/menu/ostrye-sushi","GroupTitle":"Острые суши","ID":"e8768d3c-df2d-42aa-a6fd-71a22ad253e0","MetaTags":[{"Content":"Суши Острая креветка 240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суши Острая креветка 240 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"6b2160c7-6a39-42c1-b8bf-a82a009e121b","Title":"Суши Острая креветка: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|e87afd26-c95b-4c99-82d2-22b168d2d83e",
    path: "/product/roll-s-ugrem",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"e87afd26-c95b-4c99-82d2-22b168d2d83e","MetaTags":[{"Content":"Ролл с угрем 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с угрем 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с угрем: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|e89e9765-336d-4457-83ae-a1058c55992c",
    path: "/product/roll-blek-river",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"e89e9765-336d-4457-83ae-a1058c55992c","MetaTags":[{"Content":"Блек Ривер 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Блек Ривер 490 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Блек Ривер: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|e8ddede9-d782-4443-81e1-95a461b38656",
    path: "/product/roll-s-chukoy",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"e8ddede9-d782-4443-81e1-95a461b38656","MetaTags":[{"Content":"Ролл с чукой 170 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с чукой 170 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с чукой: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f63dc1f6-ee13-452a-85b2-a82a009e1262|e90b5656-a397-4e95-b061-ce4b93d991f0",
    path: "/product/miso-sup-klassicheskiy",
    meta: {"GroupID":"f63dc1f6-ee13-452a-85b2-a82a009e1262","GroupLink":"/menu/supy","GroupTitle":"Супы","ID":"e90b5656-a397-4e95-b061-ce4b93d991f0","MetaTags":[{"Content":"Мисо суп классический 250 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мисо суп классический 250 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Мисо суп классический: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|ea59d4d3-3110-4f8d-83a8-9a4c2c308eaf",
    path: "/product/ea59d4d3-3110-4f8d-83a8-9a4c2c308eaf",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"ea59d4d3-3110-4f8d-83a8-9a4c2c308eaf","MetaTags":[{"Content":"Лимонад малина маракуйя 230 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лимонад малина маракуйя 230 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Лимонад малина маракуйя: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|ec089fe4-6f6d-4572-b318-f080338e3b83",
    path: "/product/ec089fe4-6f6d-4572-b318-f080338e3b83",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"ec089fe4-6f6d-4572-b318-f080338e3b83","MetaTags":[{"Content":"Пицца Капричоза 850 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца Капричоза 850 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца Капричоза: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|ed9c8134-f5b5-4fd4-b4d8-eafa0db3b31e",
    path: "/product/salat-s-krevetkami-i-avokado-v-kokosovo-laymovom-souse",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"ed9c8134-f5b5-4fd4-b4d8-eafa0db3b31e","MetaTags":[{"Content":"Салат с креветками и авокадо 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Салат с креветками и авокадо 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Салат с креветками и авокадо: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|eeab2160-dd4d-4794-b43e-e8e1de997687",
    path: "/product/kurinaya-grudka-su-vid-v-souse-teriyake-so-slivochnym-shpinatom-o",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"eeab2160-dd4d-4794-b43e-e8e1de997687","MetaTags":[{"Content":"Куриная грудка Су-Вид в соусе Терияке со сливочным шпинатом 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Куриная грудка Су-Вид в соусе Терияке со сливочным шпинатом 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Куриная грудка Су-Вид в соусе Терияке со сливочным шпинатом: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|eeb1e060-98d1-4e3f-b692-0584a7121fc7",
    path: "/product/roll-s-ovoschami",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"eeb1e060-98d1-4e3f-b692-0584a7121fc7","MetaTags":[{"Content":"Ролл с овощами 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с овощами 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с овощами: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|f06c8a47-bc58-4a0d-8250-1b44b4d5b7c5",
    path: "/product/khrustyaschie-kalmary",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"f06c8a47-bc58-4a0d-8250-1b44b4d5b7c5","MetaTags":[{"Content":"Хрустящие кальмары  510 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Хрустящие кальмары  510 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Хрустящие кальмары : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|f6ba830b-2569-4718-8f64-aba3007450c3|f20f6df6-1745-40a0-a727-6fcbe1ab324a",
    path: "/product/coca-cola-fanta-sprite",
    meta: {"GroupID":"f6ba830b-2569-4718-8f64-aba3007450c3","GroupLink":"/menu/napitki","GroupTitle":"Напитки","ID":"f20f6df6-1745-40a0-a727-6fcbe1ab324a","MetaTags":[{"Content":"Кола, Фанта, Спрайт, Швепс 0,25 290 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Кола, Фанта, Спрайт, Швепс 0,25 290 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Кола, Фанта, Спрайт, Швепс 0,25: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7945f4a1-9a8c-4544-9718-a82a009e163f|f31d787f-2ca1-4106-8a10-aa6773864208",
    path: "/product/sushi-ugor",
    meta: {"GroupID":"58c7c80f-2120-4bf6-8a33-a82a009e107f","GroupLink":"/menu/sushi","GroupTitle":"Классические суши","ID":"f31d787f-2ca1-4106-8a10-aa6773864208","MetaTags":[{"Content":"Суши Угорь 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Суши Угорь 300 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"7945f4a1-9a8c-4544-9718-a82a009e163f","Title":"Суши Угорь: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|f37c9b33-9d97-47e5-8732-75db25a134c7",
    path: "/product/roll-nezhnyy",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"f37c9b33-9d97-47e5-8732-75db25a134c7","MetaTags":[{"Content":"Нежный  570 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Нежный  570 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Нежный : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b176ed6a-62af-4106-b930-a82a009e17ee|f724f49c-0a78-426f-993f-724d547b5c96",
    path: "/product/losos-tataki-s-avokado-gril",
    meta: {"GroupID":"b176ed6a-62af-4106-b930-a82a009e17ee","GroupLink":"/menu/salaty","GroupTitle":"Салаты/Закуски","ID":"f724f49c-0a78-426f-993f-724d547b5c96","MetaTags":[{"Content":"Лосось-татаки с авокадо-гриль 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лосось-татаки с авокадо-гриль 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Лосось-татаки с авокадо-гриль: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|f7451902-52a6-428d-8528-1be30a95f0e6",
    path: "/product/opaleniy-tunets-s-ostrym-lososem",
    meta: {"AdditionalSeoText":"","GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"f7451902-52a6-428d-8528-1be30a95f0e6","MetaTags":[{"Content":"Тунец-терияки с острым лососем 520 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Тунец-терияки с острым лососем 520 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Тунец-терияки с острым лососем: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b|f8293272-8cc7-4334-a3fd-f051a6e26cd6",
    path: "/product/kombo-2",
    meta: {"GroupID":"139ee4bf-96ac-4b0c-8a3f-b13f00db5d3b","GroupLink":"/menu/kombo","GroupTitle":"КОМБО","ID":"f8293272-8cc7-4334-a3fd-f051a6e26cd6","MetaTags":[{"Content":"Комбо 2 1540 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Комбо 2 1540 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Комбо 2: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|f9edca82-6d90-4479-95ba-47f638219638",
    path: "/product/losos-gril-s-lapshoy-iz-tsukini",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"f9edca82-6d90-4479-95ba-47f638219638","MetaTags":[{"Content":"Лосось- гриль с лапшой из цукини 1100 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Лосось- гриль с лапшой из цукини 1100 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Лосось- гриль с лапшой из цукини: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|fa461c4b-2840-4656-b53e-a9e121c413dc",
    path: "/product/flay-fish-roll",
    meta: {"AdditionalSeoText":"","GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"fa461c4b-2840-4656-b53e-a9e121c413dc","MetaTags":[{"Content":"Флай Фиш ролл  440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Флай Фиш ролл  440 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SeoText":"","SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Флай Фиш ролл : доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|80fd2e84-50c6-4444-84d8-a82a009e0f4e|fa47ce0a-0a4f-49a3-9311-12b0436caf12",
    path: "/product/fa47ce0a-0a4f-49a3-9311-12b0436caf12",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/rolly","GroupTitle":"Классические роллы","ID":"fa47ce0a-0a4f-49a3-9311-12b0436caf12","MetaTags":[{"Content":"Ролл с острым тунцом , угрем и авокадо 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ролл с острым тунцом , угрем и авокадо 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"80fd2e84-50c6-4444-84d8-a82a009e0f4e","Title":"Ролл с острым тунцом , угрем и авокадо: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|16fc5e8e-2f38-497b-b3d3-a82a009e1570|fb88ea51-5e7f-4964-a763-213cac2c4a78",
    path: "/product/fri-rays",
    meta: {"GroupID":"de5c44ca-2016-4968-863f-a82a009e0eb8","GroupLink":"/menu/goryachie-rolly","GroupTitle":"Горячие роллы","ID":"fb88ea51-5e7f-4964-a763-213cac2c4a78","MetaTags":[{"Content":"Фри райс 570 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Фри райс 570 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"16fc5e8e-2f38-497b-b3d3-a82a009e1570","Title":"Фри райс: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6ae2f8b-5992-45cf-8877-b06f008f31f5|fcb2cba5-0a1d-4601-b789-409f8e4cfdfd",
    path: "/product/fcb2cba5-0a1d-4601-b789-409f8e4cfdfd",
    meta: {"GroupID":"b6ae2f8b-5992-45cf-8877-b06f008f31f5","GroupLink":"/menu/pizza-v-neapolitanskom-stile-p","GroupTitle":"Пицца в неаполитанском стиле","ID":"fcb2cba5-0a1d-4601-b789-409f8e4cfdfd","MetaTags":[{"Content":"Пицца Вива Италия 850 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца Вива Италия 850 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца Вива Италия: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|21ac3aae-b7d7-48aa-a182-a82a009e1685|fd9f1c90-b07d-4fd6-995c-8aeb3ad11112",
    path: "/product/befstroganov-iz-govyadiny",
    meta: {"GroupID":"295f1608-5e41-4383-a324-a82a009e0e0b","GroupLink":"/menu/goryachie-blyuda","GroupTitle":"Основные блюда","ID":"fd9f1c90-b07d-4fd6-995c-8aeb3ad11112","MetaTags":[{"Content":"Бефстроганов из говядины 750 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Бефстроганов из говядины 750 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":"21ac3aae-b7d7-48aa-a182-a82a009e1685","Title":"Бефстроганов из говядины: доставка в Малоярославце","UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|0c27a289-d611-4747-a779-f7e0d69a10f9",
    path: "/product/0c27a289-d611-4747-a779-f7e0d69a10f9",
    meta: {"GroupLink":"","ID":"0c27a289-d611-4747-a779-f7e0d69a10f9","MetaTags":[{"Content":"Азиатская  400 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Азиатская  400 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Азиатская : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|13e793a8-ce58-4509-bc17-79cd344c9393",
    path: "/product/13e793a8-ce58-4509-bc17-79cd344c9393",
    meta: {"GroupLink":"","ID":"13e793a8-ce58-4509-bc17-79cd344c9393","MetaTags":[{"Content":"Баклажаны-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Баклажаны-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Баклажаны-страчателла: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|16540612-245a-4cfb-ae17-4b8f99c369cb",
    path: "/product/16540612-245a-4cfb-ae17-4b8f99c369cb",
    meta: {"GroupLink":"","ID":"16540612-245a-4cfb-ae17-4b8f99c369cb","MetaTags":[{"Content":"Вива Италия 420 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Вива Италия 420 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Вива Италия: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|31c06cfd-8de1-4832-a1fb-ece9f373eeba",
    path: "/product/31c06cfd-8de1-4832-a1fb-ece9f373eeba",
    meta: {"GroupLink":"","ID":"31c06cfd-8de1-4832-a1fb-ece9f373eeba","MetaTags":[{"Content":"Груша - горгондзолла 380 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Груша - горгондзолла 380 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Груша - горгондзолла: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|351badad-9bac-465d-9dae-f5543bdc57df",
    path: "/product/351badad-9bac-465d-9dae-f5543bdc57df",
    meta: {"GroupLink":"","ID":"351badad-9bac-465d-9dae-f5543bdc57df","MetaTags":[{"Content":"Пепперони 340 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пепперони 340 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пепперони: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|3884defc-9431-4f53-9deb-3f23efceefd1",
    path: "/product/3884defc-9431-4f53-9deb-3f23efceefd1",
    meta: {"GroupLink":"","ID":"3884defc-9431-4f53-9deb-3f23efceefd1","MetaTags":[{"Content":"Карбонара 420 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Карбонара 420 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Карбонара: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|39a330cc-63f2-4658-a904-6a3d6fe0a714",
    path: "/product/39a330cc-63f2-4658-a904-6a3d6fe0a714",
    meta: {"GroupLink":"","ID":"39a330cc-63f2-4658-a904-6a3d6fe0a714","MetaTags":[{"Content":"Капричоза 420 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Капричоза 420 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Капричоза: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|3c4c3b1a-83ab-4480-903a-e188d809dbb5",
    path: "/product/3c4c3b1a-83ab-4480-903a-e188d809dbb5",
    meta: {"GroupLink":"","ID":"3c4c3b1a-83ab-4480-903a-e188d809dbb5","MetaTags":[{"Content":"С ростбифом и вялеными томатами 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"С ростбифом и вялеными томатами 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"С ростбифом и вялеными томатами: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|4195748f-751c-4ea5-b685-f9fd5b38b987",
    path: "/product/4195748f-751c-4ea5-b685-f9fd5b38b987",
    meta: {"GroupLink":"","ID":"4195748f-751c-4ea5-b685-f9fd5b38b987","MetaTags":[{"Content":"Маргарита  285 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Маргарита  285 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Маргарита : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|4e7d372b-2fc3-4d0a-8b55-446d76cfd486",
    path: "/product/4e7d372b-2fc3-4d0a-8b55-446d76cfd486",
    meta: {"GroupLink":"","ID":"4e7d372b-2fc3-4d0a-8b55-446d76cfd486","MetaTags":[{"Content":"Пицца 4 сыра 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца 4 сыра 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца 4 сыра: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|66fa8551-30ef-4a5c-8385-0d039bff6f4f",
    path: "/product/66fa8551-30ef-4a5c-8385-0d039bff6f4f",
    meta: {"GroupLink":"","ID":"66fa8551-30ef-4a5c-8385-0d039bff6f4f","MetaTags":[{"Content":"Пицца с кревеками и цукини  450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца с кревеками и цукини  450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца с кревеками и цукини : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|68d290ff-d774-4b93-9b17-c236e4ef5856",
    path: "/product/68d290ff-d774-4b93-9b17-c236e4ef5856",
    meta: {"GroupLink":"","ID":"68d290ff-d774-4b93-9b17-c236e4ef5856","MetaTags":[{"Content":"Мясное ассорти  410 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мясное ассорти  410 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Мясное ассорти : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|6cd11ee4-7fb9-4ed3-bead-ddd04c025266",
    path: "/product/6cd11ee4-7fb9-4ed3-bead-ddd04c025266",
    meta: {"GroupLink":"","ID":"6cd11ee4-7fb9-4ed3-bead-ddd04c025266","MetaTags":[{"Content":"Пицца с кревеками и цукини  450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца с кревеками и цукини  450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца с кревеками и цукини : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|6dd3b87b-9abc-452e-acd8-7d0fe1a3bc6b",
    path: "/product/6dd3b87b-9abc-452e-acd8-7d0fe1a3bc6b",
    meta: {"GroupLink":"","ID":"6dd3b87b-9abc-452e-acd8-7d0fe1a3bc6b","MetaTags":[{"Content":"Мортаделла-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мортаделла-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Мортаделла-страчателла: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|74e0c55c-d5e7-4272-8ddf-8d67fd7ecce9",
    path: "/product/74e0c55c-d5e7-4272-8ddf-8d67fd7ecce9",
    meta: {"GroupLink":"","ID":"74e0c55c-d5e7-4272-8ddf-8d67fd7ecce9","MetaTags":[{"Content":"Мясное ассорти  410 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мясное ассорти  410 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Мясное ассорти : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|7dc6b757-d829-44ed-b2ae-ca6f0c661168",
    path: "/product/7dc6b757-d829-44ed-b2ae-ca6f0c661168",
    meta: {"GroupLink":"","ID":"7dc6b757-d829-44ed-b2ae-ca6f0c661168","MetaTags":[{"Content":"Пепперони 340 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пепперони 340 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пепперони: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|90d75fd7-7f5e-476d-b51d-7542213c1f60",
    path: "/product/90d75fd7-7f5e-476d-b51d-7542213c1f60",
    meta: {"GroupLink":"","ID":"90d75fd7-7f5e-476d-b51d-7542213c1f60","MetaTags":[{"Content":"Баклажаны-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Баклажаны-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Баклажаны-страчателла: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|b8b05ccc-fa30-491e-adea-02828f4996f0",
    path: "/product/b8b05ccc-fa30-491e-adea-02828f4996f0",
    meta: {"GroupLink":"","ID":"b8b05ccc-fa30-491e-adea-02828f4996f0","MetaTags":[{"Content":"Пицца 4 сыра 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца 4 сыра 450 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца 4 сыра: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|b9d0501d-9d38-4f28-bab3-2ea89bd0c4e5",
    path: "/product/b9d0501d-9d38-4f28-bab3-2ea89bd0c4e5",
    meta: {"GroupLink":"","ID":"b9d0501d-9d38-4f28-bab3-2ea89bd0c4e5","MetaTags":[{"Content":"Груша - горгондзолла 380 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Груша - горгондзолла 380 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Груша - горгондзолла: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|be006d94-08d1-49b6-9240-869a5f673464",
    path: "/product/be006d94-08d1-49b6-9240-869a5f673464",
    meta: {"GroupLink":"","ID":"be006d94-08d1-49b6-9240-869a5f673464","MetaTags":[{"Content":"Маргарита  285 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Маргарита  285 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Маргарита : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|c602d4f9-be85-4201-9328-5a8a576e658d",
    path: "/product/c602d4f9-be85-4201-9328-5a8a576e658d",
    meta: {"GroupLink":"","ID":"c602d4f9-be85-4201-9328-5a8a576e658d","MetaTags":[{"Content":"Азиатская  400 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Азиатская  400 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Азиатская : доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|d36b6db2-2092-439c-a033-8782bbda7c1a",
    path: "/product/d36b6db2-2092-439c-a033-8782bbda7c1a",
    meta: {"GroupLink":"","ID":"d36b6db2-2092-439c-a033-8782bbda7c1a","MetaTags":[{"Content":"Пицца Цезарь 360 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца Цезарь 360 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца Цезарь: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|dd52939f-c80f-417c-a57f-9f6e1459966a",
    path: "/product/dd52939f-c80f-417c-a57f-9f6e1459966a",
    meta: {"GroupLink":"","ID":"dd52939f-c80f-417c-a57f-9f6e1459966a","MetaTags":[{"Content":"Ветчина-грибы 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ветчина-грибы 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Ветчина-грибы: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|e0a549f8-364d-48ab-9de1-80b9f7df1e09",
    path: "/product/e0a549f8-364d-48ab-9de1-80b9f7df1e09",
    meta: {"GroupLink":"","ID":"e0a549f8-364d-48ab-9de1-80b9f7df1e09","MetaTags":[{"Content":"Мортаделла-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Мортаделла-страчателла 0 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Мортаделла-страчателла: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|e79375e1-6dd8-4d06-9deb-8c62deb69a97",
    path: "/product/e79375e1-6dd8-4d06-9deb-8c62deb69a97",
    meta: {"GroupLink":"","ID":"e79375e1-6dd8-4d06-9deb-8c62deb69a97","MetaTags":[{"Content":"Пицца Цезарь 360 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Пицца Цезарь 360 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Пицца Цезарь: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "product|7d64ee43-1c34-45c0-baa3-b08a00e6730c|ea147fd4-308b-4503-8a62-80c5e66a6f59",
    path: "/product/ea147fd4-308b-4503-8a62-80c5e66a6f59",
    meta: {"GroupLink":"","ID":"ea147fd4-308b-4503-8a62-80c5e66a6f59","MetaTags":[{"Content":"Ветчина-грибы 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"description"},{"Content":"Ветчина-грибы 350 ₽ рублей – в меню на сайте Miyake. Бесплатная доставка на дом, удобный онлайн заказ, оплата наличными или картой.","Name":"og:description"}],"SubgroupID":null,"Title":"Ветчина-грибы: доставка в Малоярославце"},
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/menu/product/[id].vue")
  },
  {
    name: "legal-pay-rules",
    path: "/rules",
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/legal.vue")
  },
  {
    name: "legal-personal-data",
    path: "/personaldata",
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/legal.vue")
  },
  {
    name: "legal-agreement",
    path: "/privacy",
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/legal.vue")
  },
  {
    name: "legal-points",
    path: "/points",
    component: () => import("D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/pages/legal.vue")
  }
]