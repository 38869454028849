
import * as aroraProviderRuntime$yP_45_45XSSMW_45llCJJRebsNSe4CPV_45oRg73p_DqsbEszXg from 'D:/deploy/UploadHidden/20250402-P903.318/arora-front/satellite/build-rest-fa4c0594-b570-4a82-bc0a-ec2c7721e3b6/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$yP_45_45XSSMW_45llCJJRebsNSe4CPV_45oRg73p_DqsbEszXg, defaults: {} }
}
        